

import { Button } from "@chakra-ui/button";
import { Flex, Heading, HStack, Text, Stack } from "@chakra-ui/layout";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
} from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import React, { useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { addToCart } from "./redux/cartSlice";
import { useToast } from "@chakra-ui/toast";
import { Skeleton } from "@chakra-ui/react";

function AddToCart({ product }) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const CheckVariants = () => {
    setLoading(true);
    if (product.variants.length === 1) {
      addCart(product.variants[0]);
    } else {
      onOpen();
    }
  };

  const addCart = async (variant) => {
    console.log(variant);
    const result = dispatch(addToCart({ ...variant, product: product }));
    console.log(result);
    setLoading(false);
    toast({
      title: "Product Added to Busket.",
      description: product.title + " added to basket",
      status: "success",
    });
  };

  return (
    <>
      <Skeleton isLoaded={product} w="100%">
                <Button  isLoading={loading}
        onClick={CheckVariants} colorScheme="green" w="100%" size="lg">Add To Cart</Button>
       </Skeleton>
      {product && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent overflow="hidden">
            <ModalHeader bg="blue.500" shadow="lg" color="white">
              {product && product.title}
            </ModalHeader>
            <ModalCloseButton color="white" />
            <ModalBody>
              <Heading size="xs">
                <Text>Select an Option</Text>
              </Heading>
              <Stack>
                {product.variants.map((variant) => (
                  <HStack key={variant.id} w="100%">
                    <Text>${variant.price}</Text>
                    <Text>{variant.title}</Text>
                    <Flex justify="end" flexGrow="1">
                      <Button
                        float="rignt"
                        onClick={() => {
                          onClose();
                          addCart(variant);
                        }}
                      >
                        <AddIcon />
                      </Button>
                    </Flex>
                  </HStack>
                ))}
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default AddToCart;