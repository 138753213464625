import { Heading, Image, Skeleton, Stack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

export default function BlogItem({blog}) {
  return (
    <Stack pb="20px">
        <Link to={`/blog/${blog.handle}`}><Skeleton borderRadius="10px" isLoaded={blog}><Image src={blog?.image?.src || "/blogItem.png"} h="150" aspectratio="2:1" objectFit={"cover"} borderRadius="10px" w="100%" /></Skeleton></Link>
        <Skeleton isLoaded={blog}><Heading size="sm" color="gray.700">{blog?.title || "Lorem Ipsum De"}</Heading></Skeleton>
    </Stack>
  )
}
