import { Button } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { ChevronLeftIcon } from "@chakra-ui/icons";

import { Center, HStack, Stack, Text, Flex, Circle } from "@chakra-ui/layout";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/modal";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import CartItem from "./CartItem";
import { BsCartFill } from "react-icons/bs";
import { client } from "./shopify";

function CartBox() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [didMount, setDidMount] = useState(false);

  const cart = useSelector((state) => state.cart.value);
  useEffect(() => {
    if (didMount) {
      onOpen();
    } else {
      //didMount.current = true;
      setDidMount(true);
    }
  }, [cart]);


  const [loading, setLoading] = useState(false);

  const checkOut = () => {
    setLoading(true);
    client.checkout.create().then((checkout) => {
        // Do something with the checkout
        const lineItemsToAdd = [];
        cart.map((item) => {
            lineItemsToAdd.push({
                variantId: item.id,
                quantity: item.quantity
            });
            return null; 
        });
        
        client.checkout.addLineItems(checkout.id, lineItemsToAdd).then((checkout) => {
            // Do something with the updated checkout
            window.location.href = checkout.webUrl;
        });
          
    });
  }
  return (
    <>
      <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}
          cursor="pointer"
          onClick={onOpen}>
          <HStack align="center" padding="5px" pl="10px" position="relative" cursor={'pointer'}>
            <Flex align="center" pr="10px" fontSize="20px">
              <BsCartFill />
            </Flex>
            <Circle size='20px' top="-5px" right="-0px" bg='green.400' color='white' position="absolute">
             {cart.length}
            </Circle>
          </HStack>
        </Stack>
      <Drawer isOpen={isOpen} size="sm" placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader bg="blue.50">Basket ({cart.length})</DrawerHeader>

          <DrawerBody pt="10">
            <Stack spacing="20px">
              {cart.map((cartitem, index) => (
                <CartItem key={index} cartitem={cartitem} />
              ))}
            </Stack>
            {cart.length === 0 && (
              <Center h="100%">
                <Stack align="center">
                  <MdOutlineRemoveShoppingCart size="60px" color="#3f3f3f" />
                  <Text color="gray.500">Your basket is empty</Text>
                  <Button
                    onClick={onClose}
                    colorScheme="green"
                    leftIcon={<ChevronLeftIcon />}
                  >
                    Continue Shopping
                  </Button>
                </Stack>
              </Center>
            )}
          </DrawerBody>

          {cart.length > 0 && (
            <DrawerFooter bg={"green.500"} w="100%" justify="space-between">
              <HStack w="100%" justify="space-between">
                <Text fontWeight="bold" color="white">
                  Subtotal: US${" "}
                  {cart
                    .reduce(
                      (previousValue, currentValue) =>
                        previousValue +
                        currentValue.price * currentValue.quantity,
                      0
                    )
                    .toFixed(2)}
                </Text>
           
                  <Button
                    variant="outline"
                    _hover={{ bg: "rgba(0,0,0,.3)" }}
                    color="white"
                    mr={3}
                    onClick={checkOut}
                    isLoading={loading}
                  >
                    Checkout
                  </Button>
            
              </HStack>
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default CartBox;