import { Box } from '@chakra-ui/react'
import React from 'react'
import DesktopNavigation from './DesktopNavigation'
import MobileNavigation from './MobileNavigation'

export default function NavigationSection() {
  return (
    <Box>

        <DesktopNavigation />
        <MobileNavigation />

    </Box>
  )
}
