import { Box, Heading, HStack, Image, Skeleton, Stack, Text, Wrap } from '@chakra-ui/react'
import React from 'react'
import { MdOutlineInsertChartOutlined, MdOutlineStar } from 'react-icons/md'
import Rating from 'react-rating'
import { Link } from 'react-router-dom'

export default function SimpleProduct({product}) {
  return (
    <Link to={`/product/${product?.handle}`}>
        <Stack pb="20px">
            <Box px="30px">
                <Skeleton isLoaded={product} borderRadius="10px">
                    <Image src={
                        product &&
                        product.images &&
                        product.images.length > 0 &&
                        product.images[0].src
                        }
                        fallbackSrc={"/simple.png"} 
                        w="100%" 
                        borderRadius="10px" 
                    />
                </Skeleton>
            </Box>
       
            <Skeleton isLoaded={product}>
                <Stack bg="white" _hover={{bg: "green.50"}} p="5px" borderRadius="10px" shadow="md" style={{marginTop: "-90px"}}>
                    <Heading size="xs">{product?.title}</Heading>
                    <HStack w="100%" justify="space-between">
                        <Wrap>
                            <Rating
                                initialRating={5}
                                readonly
                                emptySymbol={<Wrap color="orange.500"><MdOutlineInsertChartOutlined /></Wrap>}
                                placeholderSymbol={<Wrap color="gray.100"><MdOutlineStar /></Wrap>}
                                fullSymbol={<Wrap color="yellow.400"><MdOutlineStar /></Wrap>}
                            />
                        </Wrap>
                        <HStack pr="10px" pb="5px" align="start" spacing="2px">
                                <Text fontWeight="700">${product?.variants[0]?.price}</Text>
                                {product?.variants[0]?.price < product?.variants[0]?.compareAtPrice &&  <Text fontSize="xs" color="red.500">${product?.variants[0]?.compareAtPrice}</Text>}
                        </HStack>
                    </HStack>
                </Stack>
            </Skeleton>
           
        </Stack>
    </Link>
  )
}
