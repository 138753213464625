import { Grid, Heading, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { client } from '../../utils/shopify';
import SimpleProduct from '../elements/SimpleProduct';

export default function RelatedProducts({product}) {
 
  const [products, setProducts] = useState(Array(4));

  useEffect(() => {
    setProducts([]);
    const query = client.graphQLClient.query((root) => {
      root.add(
        "productRecommendations",
        { args: { productId: product.id } },
        (node) => {
          node.add("id");
          node.add("title");
          node.add("handle");
          node.addField("options", (option) => {
            option.add("id");
            option.add("name");
            option.add("values");
          });

          node.addConnection("variants", { args: { first: 20 } }, (variant) => {
            variant.add("id");
            variant.add("title");
            variant.add("price"); // Add fields to be returned
            variant.addField("selectedOptions", {}, (field) => {
              field.add("name");
              field.add("value");
            });
            variant.addField("image", (image) => {
              image.add("id");
              image.add("src");
            });
          });

          node.add("vendor");
          node.add("descriptionHtml");
          node.addConnection("images", { args: { first: 20 } }, (image) => {
            image.add("src");
          });
          node.addConnection(
            "collections",
            { args: { first: 5 } },
            (collection) => {
              collection.add("id");
              collection.add("title");
              collection.add("handle");
            }
          );
        }
      );
    });

    // // Call the send method with the custom products query
    client.graphQLClient.send(query).then(({ model, data }) => {
      // Do something with the products
      console.log(model);
      setProducts(
        model.productRecommendations.length > 5
          ? model.productRecommendations.slice(0, 5)
          : model.productRecommendations
      );
      // setCollections(data.nodes);
    });
  }, [product]);
  return (
    <Stack>
        <Heading size="md">You might also like</Heading>
        <Grid templateColumns={{base: "1fr 1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr 1fr 1fr"}} gap="10px">
                {products.map((product, index) => (
                    <SimpleProduct product={product} key={index} />
                ))}
        </Grid>
    </Stack>
  )
}
