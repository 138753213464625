import { Container, Divider, Grid, Heading, HStack, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import SimpleProduct from '../components/elements/SimpleProduct'
import LatestProducts from '../components/partials/LatestProducts'
import Newsletter from '../components/partials/Newsletter'

export default function Category() {
  return (
    <Stack spacing="50px">

   <Container maxW="975px" pt="50px">
       <Stack spacing="20px">
            <HStack bg="white" p="10px" borderRadius="5px" justify="space-between" boxShadow="lg">
                <Heading size="sm">Our Catalog</Heading>
                <HStack><Text fontSize="sm">Sort: Low to High</Text></HStack>
            </HStack>
            <Grid templateColumns={{base: "1fr 1fr", md: "1fr 1fr 1fr 1fr"}} gap="20px">
                <SimpleProduct />
                <SimpleProduct />
                <SimpleProduct />
                <SimpleProduct />
                <SimpleProduct />
                <SimpleProduct />
                <SimpleProduct />
                <SimpleProduct />
                <SimpleProduct />
                <SimpleProduct />
                <SimpleProduct />
                <SimpleProduct />
                <SimpleProduct />
                <SimpleProduct />
                <SimpleProduct />
                <SimpleProduct />
            </Grid>
            
       </Stack>
   </Container>
   <Divider />
    <Container pt="5" style={{    width: "100%",
    maxWidth: "1100px",
    margin: "0 auto"}}>
    <LatestProducts />
   
    </Container>
    <Newsletter />
    </Stack>
  )
}
