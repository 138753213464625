import { Center, Container, Heading, Image, Spinner, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { client } from '../utils/shopify';

export default function BlogPage() {

  const [article, setArticle] = useState();  

  const {slug} = useParams();  

 
    useEffect(() => {
        const query = client.graphQLClient.query((root) => {
          root.addConnection("articles", { args: { first: 1, reverse: true, query: "handle:"+slug} }, (node) => {
      
            node.add("id");
            node.add("title");
            node.add("contentHtml");
            node.add("excerpt");
            node.add("handle");
    
            node.add("image", (image) => {
              image.add("src");
            });
    
          });
        });
    
        // Call the send method with the custom products query
        client.graphQLClient.send(query).then(({ model, data }) => {
          // Do something with the products
          console.log(model);
          setArticle(model?.articles[0]);
        //   setBlogs(model.articles);
          console.log(data);
          //setProduct(model.productByHandle);
          //setVariant(model.productByHandle.variants[0]);
          //setLoading(false);
          //getStore(model.productByHandle);
        });
      }, [slug]);
    

  return (
    <Container pt="10">
        <Image position="absolute" zIndex="-1" opacity="0.1" width="100%" left="0" right="0" top="0" src={article?.image?.src || "/blogItem.png"} borderRadius="10px" />
        <Center>
            {!article && <Spinner />}
        </Center>
        {article &&
        (
            <Stack fontSize="xs" spacing={"20px"}>
                <Heading>{article?.title}</Heading>
                <Image src={article?.image?.src || "/blogItem.png"} borderRadius="10px" />
                <div dangerouslySetInnerHTML={{__html: article?.contentHtml}}></div>
            </Stack>
        )}
    </Container>
  )
}
