import { Box } from '@chakra-ui/react';
import React from 'react'
import "./Birds.css";

export default function Birds() {
  return (
    <Box position="relative" top="20px" zIndex="1">
        <div className="bird-container bird-container--one">
            <div className="bird bird--one"></div>
        </div>
        
        <div className="bird-container bird-container--two">
            <div className="bird bird--two"></div>
        </div>
        
        <div className="bird-container bird-container--three">
            <div className="bird bird--three"></div>
        </div>
        
        <div className="bird-container bird-container--four">
            <div className="bird bird--four"></div>
        </div>
    </Box>
  )
}
