import { Container, HStack, Image, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ReactTypingEffect from 'react-typing-effect';

export default function Greeting() {

    const [words, setWords] = useState([]);
    const [type, setType] = useState();
    const [desc, setDesc] = useState();
    const calcTime = (offset) => {
        // create Date object for current location
        var d = new Date();
    
        // convert to msec
        // subtract local time zone offset
        // get UTC time in msec
        var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    
        // create new Date object for different city
        // using supplied offset
        var nd = new Date(utc + (3600000*offset));
    
        const hours = nd.getHours();

        if(hours < 12){
            setWords(["Good Morning", "Manheru", "Litshonile"]);
        }else if(hours < 16){
            setWords(["Good Afternoon", "Manheru", "Litshonile"]);
        }else{
            setWords(["Good Evening", "Manheru", "Litshonile"]);
        }

        // return time as a string
        return hours;
    }

    

    useEffect(() => {

        calcTime(2);

        axios.get('https://api.openweathermap.org/data/2.5/weather?q=Harare&appid=0a644280ce51d4d8d422d756b928743e')
        .then((res) => res.data)
        .then((data) => {
            setType(data?.weather[0]?.icon);
            setDesc(data?.weather[0]?.description);
        })
        .catch((err) => {

        });


    }, []);
    

   
  return (
    <Container maxW="1160px">
            <HStack py="2">
                <Text fontSize="xs">{desc}</Text>
                <Image src={type ? "http://openweathermap.org/img/wn/"+type+"@2x.png" : "/sunset.png"} w="30px" />
                <HStack align="center">
                        <strong><ReactTypingEffect  text={words} /></strong>  <Text> from Zimbabwe</Text>
                </HStack>
            </HStack>
    </Container>
  )
}
