import { Box, Container, Heading, Image, Link, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { client } from '../utils/shopify';

export default function BlogsPage() {

  const [blogs, setBlogs] = useState(Array(4));

  useEffect(() => {
    const query = client.graphQLClient.query((root) => {
      root.addConnection("articles", { args: { first: 10, reverse: true} }, (node) => {
  
        node.add("id");
        node.add("title");
        node.add("contentHtml");
        node.add("content");
        node.add("excerpt");
        node.add("handle");

        node.add("image", (image) => {
          image.add("src");
        });

      });
    });

    // Call the send method with the custom products query
    client.graphQLClient.send(query).then(({ model, data }) => {
      // Do something with the products
      console.log(model);
      setBlogs(model.articles);
      console.log(data);
      //setProduct(model.productByHandle);
      //setVariant(model.productByHandle.variants[0]);
      //setLoading(false);
      //getStore(model.productByHandle);
    });
  }, []);

  return (
    <Container maxW="795px">
        <Heading align="center" mb="10" pt="10">Our Blogs</Heading>
        <Stack spacing="30px">
            {blogs.map((blog, index) => (
             <Box
             key={index}
             marginTop={{ base: '1', sm: '5' }}
             display="flex"
             flexDirection={{ base: 'column', sm: 'row' }}
             justifyContent="space-between">
             <Box
               display="flex"
               flex="1"
               marginRight="3"
               position="relative"
               alignItems="center">
               <Box
                 width={{ base: '100%', sm: '85%' }}
                 zIndex="2"
                 marginLeft={{ base: '0', sm: '5%' }}
                 marginTop="5%">
                 <Link textDecoration="none" _hover={{ textDecoration: 'none' }} href={`/blog/${blog.handle}`}>
                   <Image
                     borderRadius="lg"
                     src={
                        blog?.image?.src || "/blogItem.png"
                     }
                     alt="some good alt text"
                     objectFit="contain"
                   />
                 </Link>
               </Box>
               <Box zIndex="1" width="100%" position="absolute" height="100%">
                 <Box
                   bgGradient={'radial(orange.600 1px, transparent 1px)'}
                   backgroundSize="20px 20px"
                   opacity="0.4"
                   height="100%"
                 />
               </Box>
             </Box>
             <Box
               display="flex"
               flex="1"
               flexDirection="column"
               justifyContent="center"
               marginTop={{ base: '3', sm: '0' }}>
               <Heading marginTop="1" fontSize="lg">
                 <Link textDecoration="none" _hover={{ textDecoration: 'none' }} href={`/blog/${blog.handle}`}>
                   {blog?.title}
                 </Link>
               </Heading>
               <Text
                 as="p"
                 marginTop="2"
                 color={'gray.700'}
                 fontSize="lg">
                 {blog?.content.substring(0,100) + "..."}
               </Text>
             </Box>
           </Box>
            ))}
        </Stack>
    </Container>
  )
}
