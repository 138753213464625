import { Container, Stack } from '@chakra-ui/react'
import React from 'react'
import BlogSection from '../components/partials/BlogSection'
import HeroSection from '../components/partials/HeroSection'
import LatestProducts from '../components/partials/LatestProducts'
import Newsletter from '../components/partials/Newsletter'
import Recommended from '../components/partials/Recommended'

export default function Home() {
  return (
    <Stack>
       <HeroSection />
       <Container maxW="1440px">
        <Stack spacing="50px">
            <LatestProducts />
            <Recommended />
            <BlogSection />
            <Newsletter />
        </Stack>
    </Container>
    </Stack>
    
   
  )
}
