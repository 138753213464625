import { Box, Button, Image, Input, Stack, Text } from '@chakra-ui/react'
import React from 'react'

export default function DHL() {
  return (
    <Stack borderColor="green.400" borderWidth="1px" borderRadius="5px" align="center" py="10px" spacing="30px">
        <Box w="100%"><Image w="70%" src="/dhl_header.png" /></Box>
        <Text textAlign="center" maxW="300px" p="10px">With the help of our shipping partners we can ship to over 250 countries</Text>
        <Stack w="100%">
            <Box px="10px" w="100%"><Input bg="gray.50" placeholder="Email or Tracking Code" /></Box>
            <Box px="10px" w="100%"><Button w="100%" bg="green.400" colorScheme="green">Track Order</Button></Box>
        </Stack>
    </Stack>
  )
}
