import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Circle } from "@chakra-ui/layout";
import React from "react";

function SlickPrevArrow(props) {
  const { onClick, hover, margin } = props;
  return (
    <Circle
      bg={"gray.700"}
      opacity={hover ? "0.5" : "0"}
      cursor="pointer"
      _hover={{ opacity: 1 }}
      position="absolute"
      top="calc(50% - 15px)"
      left={margin}
      h="30px"
      w="30px"
      onClick={onClick}
      zIndex="2"
      transition="all 0.35s ease-in-out"
    >
      <ChevronLeftIcon color="white" />
    </Circle>
  );
}

export default SlickPrevArrow;