import { Box, Image, Link, Stack } from '@chakra-ui/react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useState } from 'react'
import SlickNextArrow from '../elements/utils/SlickNextArrow';
import SlickPrevArrow from '../elements/utils/SlickPrevArrow';
import Greeting from '../elements/Greeting';
import axios from 'axios';

export default function HeroSection() {

    const [hover] = useState(true);


    const [banners, setBanners] = useState([]);

    useEffect(() => {

      axios.get('https://o81qy7ln.api.sanity.io/v2021-10-21/data/query/production?query=*%5B_type%20%3D%3D%20%22banner%22%5D%20%7B%0A%20%20...%2C%0A%20%20%22imageUrl%22%3A%20src.asset-%3Eurl%0A%7D')
      .then((res) => res.data)
      .then((data) => {
        setBanners(data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    }, []);


    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        variableWidth: false,
        nextArrow: <SlickNextArrow hover={hover} margin="10px" />,
        prevArrow: <SlickPrevArrow hover={hover} margin="10px" />,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 675,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

  return (
       <Stack mt="8">
           <Greeting />
           
           <Box w="100%" position="relative" overflowX="hidden">
            {banners &&  <Slider {...settings}>
              {banners.map((banner, index) => (
                  <Link key={index} href={banner.url}>
                  <Image src={banner?.imageUrl}  background="#16613D" w="100%" />
                </Link>
              ))}
            </Slider>}
           
           </Box>
           
       </Stack>
  )
}
