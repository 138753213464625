import { chakra, Box, Container, Grid, Heading, HStack, Image, Stack, Text, VisuallyHidden, Wrap, useColorModeValue, Skeleton } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdOutlineInsertChartOutlined, MdOutlineStar } from 'react-icons/md'
import Rating from 'react-rating'
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import Newsletter from '../components/partials/Newsletter';
import { useParams } from 'react-router-dom';
import { client } from '../utils/shopify';
import AddToCart from '../utils/AddToCart';
import RelatedProducts from '../components/partials/RelatedProducts';

export default function ProductPage() {

    let { slug } = useParams();
    const [product, setProduct] = useState();
    useEffect(() => {

        const query = client.graphQLClient.query((root) => {
            root.add("productByHandle", { args: { handle: slug } }, (node) => {
              node.add("id");
              node.add("title");
              node.addField("options", (option) => {
                option.add("id");
                option.add("name");
                option.add("values");
              });
      
              node.addConnection("variants", { args: { first: 20 } }, (variant) => {
                variant.add("id");
                variant.add("title");
                variant.add("price");
                variant.add("compareAtPrice"); // Add fields to be returned
                variant.addField("selectedOptions", {}, (field) => {
                  field.add("name");
                  field.add("value");
                });
                variant.addField("image", (image) => {
                  image.add("id");
                  image.add("originalSrc");
                });
              });
      
              node.add("vendor");
              node.add("descriptionHtml");
              node.add("description");
              node.addConnection("images", { args: { first: 20 } }, (image) => {
                image.add("originalSrc");
              });
              node.addConnection(
                "collections",
                { args: { first: 5 } },
                (collection) => {
                  collection.add("id");
                  collection.add("title");
                  collection.add("handle");
                }
              );
            });
          });
      
          // Call the send method with the custom products query
          client.graphQLClient.send(query).then(({ model, data }) => {
            // Do something with the products
            console.log(model);
            setProduct(model.productByHandle);
            //setVariant(model.productByHandle.variants[0]);
            //setLoading(false);
            //getStore(model.productByHandle);
          });



    }, [slug]);

    console.log(Math.floor(((product?.variants[0].price)/(product?.variants[0].compareAtPrice)) * 100))
  return (
    <Container maxW="1440px" pt="100px">
        <Stack spacing="60px">
        <Grid templateColumns={{base: "100%", md: "8fr 4fr"}} gap="20px">
            <Grid bg="white" p="5" borderRadius="5px" shadow={"md"} templateColumns={{base: "100%", md: "3fr 9fr"}} gap="20px">
                <Box position="relative" pb="20px">
                    <Skeleton isLoaded={product}>
                        <Image src={ product?.images[0]?.originalSrc || "/flowerdress.png" } borderRadius="10px" pl="10px" />
                    </Skeleton>
                    {product?.variants[0].price < product?.variants[0].compareAtPrice && (
                        <Stack color="white" bg="green.400" position="absolute" left="0" top="-10px" spacing="0" p="2" borderRadius="5px">
                            <Text mb="0">{Math.floor(((product?.variants[0].price)/(product?.variants[0].compareAtPrice)) * 100)}%</Text>
                            <Text>OFF</Text>
                        </Stack>
                    )}
                    
                </Box>

                <Stack justify="end" pb="20px">
                    <Skeleton isLoaded={product}><Heading>{product?.title || "Product Title Lorem ipsum dolor sit amet"}</Heading></Skeleton>
                    <HStack>
                        <Wrap>
                            <Rating
                                initialRating={5}
                                readonly
                                emptySymbol={<Wrap color="orange.500"><MdOutlineInsertChartOutlined /></Wrap>}
                                placeholderSymbol={<Wrap color="gray.100"><MdOutlineStar /></Wrap>}
                                fullSymbol={<Wrap color="yellow.400"><MdOutlineStar /></Wrap>}
                            />
                        </Wrap> 
                        <Text color="green.700" textDecoration="underline">No Reviews</Text>
                    </HStack>
                    <Text>{product?.description?.substring(0,100)}...</Text>
                    <Text fontWeight="bold" textDecoration="underline">View More</Text>
                </Stack>
            </Grid>

            <Stack bg="white" p="5" borderRadius="5px" shadow={"md"} justify="space-between">
                <Stack>
                <HStack align="start">
                    <Skeleton isLoaded={product}><Heading>USD ${product?.variants[0]?.price}</Heading></Skeleton>
                    {product?.variants[0].price < product?.variants[0].compareAtPrice &&  <Text color="red">${product.variants[0].compareAtPrice}</Text>}
                </HStack>
                <HStack>
                    <Text>Shipping: </Text>
                    <Text textDecoration="underline">Here is where we ship</Text>
                </HStack>
                <HStack pt="5">
                    <Text>Share: </Text>
                    <Stack direction={'row'} spacing={1}>
                        <SocialButton label={'Twitter'} href={'#'}>
                            <FaTwitter />
                        </SocialButton>
                        <SocialButton label={'YouTube'} href={'#'}>
                            <FaYoutube />
                        </SocialButton>
                        <SocialButton label={'Instagram'} href={'#'}>
                            <FaInstagram />
                        </SocialButton>
                    </Stack>
                </HStack>
                </Stack>
                <AddToCart product={product} />
            </Stack>
        </Grid>
        <Stack maxW="750px">
            <Heading size="md">Product Details</Heading>
            <Box color="gray.700" pl="30px"   dangerouslySetInnerHTML={{
                      __html: product?.descriptionHtml,
                    }}>
                
            </Box>
        </Stack>
         {product && <RelatedProducts product={product} />}
         <Newsletter />
        </Stack>
    </Container>
  )
}

const SocialButton = ({
    children,
    label,
    href,
  }) => {
    return (
      <chakra.button
        bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'a'}
        href={href}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        _hover={{
          bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
        }}>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    );
  };
