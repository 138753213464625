import { Button, ButtonGroup } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Box, Grid, HStack, Stack, Text } from "@chakra-ui/layout";
import React from "react";
import { BsTrash } from "react-icons/bs";
import { useDispatch } from "react-redux";
import {
  addToCart,
  reduceToCart,
  removeFromCart,
} from "./redux/cartSlice";

function CartItem({ cartitem }) {
  const dispatch = useDispatch();

  const addCart = () => {
    dispatch(addToCart(cartitem));
  };

  const reduceCart = () => {
    dispatch(reduceToCart(cartitem));
  };

  const removeCart = () => {
    dispatch(removeFromCart(cartitem));
  };
  return (
    <Grid templateColumns="2fr 8fr" gap="10px">
      <Image
        w="100%"
        h="auto"
        src={
          cartitem?.image?.src
            ? cartitem.image.src
            : cartitem?.image?.originalSrc
        }
        fallbackSrc="https://source.unsplash.com/900x900"
      />
      <Stack>
        <HStack justify="space-between">
          <Text fontSize="0.85em">{cartitem.product.title}</Text>
          <Box w="20px">
            <BsTrash
              onClick={removeCart}
              height="20px"
              width="20px"
              style={{
                height: "20px !important",
                width: "20px !important",
                cursor: "pointer",
              }}
              flexgrow="1"
            />
          </Box>
        </HStack>
        <Text fontSize="0.8em" color="gray.500">
          {cartitem.title === "Default Title"
            ? "@ $" + cartitem.price + " each"
            : cartitem.title + "   @ $" + cartitem.price}
        </Text>
        <ButtonGroup isAttached>
          <Button size="sm" onClick={reduceCart}>
            -
          </Button>
          <Button size="sm" disabled={true} bg={"gray.100"}>
            {cartitem.quantity}
          </Button>
          <Button size="sm" onClick={addCart}>
            +
          </Button>
        </ButtonGroup>
      </Stack>
    </Grid>
  );
}

export default CartItem;