import React from 'react'
import { HStack, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import {FaFacebookSquare, FaTwitterSquare, FaInstagramSquare} from "react-icons/fa"

export default function HeaderTopSection() {
  return (
    <HStack backgroundColor="#16613D" justify="end" padding="2" color="white">
       
        <HStack spacing="10px">
            <Link to="#">
                <Text fontSize="xs">Track Order</Text>
            </Link>
            <Link to="#">
                <FaFacebookSquare size={25} />
            </Link>
            <Link to="#">
                <FaTwitterSquare size={25} />
                
            </Link>
            <Link to="#">
                <FaInstagramSquare size={25} />
            </Link>
        </HStack>
    </HStack>
  )
}
