import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: sessionStorage.getItem("cart")
    ? JSON.parse(sessionStorage.getItem("cart"))
    : [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //sessionStorage.setItem("categories", JSON.stringify(action.payload));
      //state.value = action.payload;

      let existing = sessionStorage.getItem("cart")
        ? JSON.parse(sessionStorage.getItem("cart"))
        : [];

      console.log(existing);

      let found = existing.filter((variant, index) => {
        if (variant.id === action.payload.id) {
          existing[index].quantity++;
          return true;
        }
        return false;
      });

      if (found.length === 0) {
        existing.push({ ...action.payload, quantity: 1 });
      }
      console.log(existing);
      sessionStorage.setItem("cart", JSON.stringify(existing));
      state.value = existing;

      return state;
    },
    reduceToCart: (state, action) => {
      let existing = sessionStorage.getItem("cart")
        ? JSON.parse(sessionStorage.getItem("cart"))
        : [];

      existing.filter((variant, index) => {
        if (variant.id === action.payload.id) {
          if (variant.quantity > 1) {
            existing[index].quantity--;
            return variant;
          } else {
            existing.splice(index, 1);
          }
        }
        return [];
      });

      sessionStorage.setItem("cart", JSON.stringify(existing));
      state.value = existing;
    },
    removeFromCart: (state, action) => {
      let existing = sessionStorage.getItem("cart")
        ? JSON.parse(sessionStorage.getItem("cart"))
        : [];

      existing.filter((variant, index) => {
        if (variant.id === action.payload.id) {
          existing.splice(index, 1);
        }
        return [];
      });

      sessionStorage.setItem("cart", JSON.stringify(existing));
      state.value = existing;
    },
    clearCart: (state, action) => {
      sessionStorage.setItem("cart", JSON.stringify([]));
      state.value = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addToCart, reduceToCart, removeFromCart, clearCart } =
  cartSlice.actions;

export default cartSlice.reducer;