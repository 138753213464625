
import { Stack } from '@chakra-ui/react'
import React from 'react'
import HeaderTopSection from '../elements/headerTopSection'
import NavigationSection from '../elements/navigationSection'

export default function Header() {
  return (
   <Stack>
     <HeaderTopSection />
     <NavigationSection />
   </Stack>
  )
}
