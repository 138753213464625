import { Grid, Heading, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { client } from '../../utils/shopify';
import DHL from '../elements/DHL'
import SimpleProduct from '../elements/SimpleProduct'

export default function Recommended() {



  const [products, setProducts] = useState(Array(4));

  useEffect(() => {
    setProducts([]);
    
    client.product
    .fetchQuery({
      first: 4,
      sortKey: "CREATED_AT",
      reverse: true,
      query: "tag:recommended"
    })
    .then((results) => {
      // Do something with the products
      console.log(results);
      setProducts(results);
      // setLoading(false);
    })
    .catch((err) => {
      console.log(err);
    });

  }, []);



  return (
    <Grid templateColumns={{base: "100%", md: "300px 1fr"}} gap="15px" style={{paddingTop: "60px", paddingBottom: "40px"}}>
        <DHL />
        <Stack spacing="20px">
            <Heading size="md" pl="20px">Recommended for you</Heading>
            <Grid templateColumns={{base: "1fr 1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr 1fr"}} gap="10px">

              {products.map((product, index) => (
                  <SimpleProduct product={product} key={index} />
              ))}
               
              
            </Grid>
        </Stack>
    </Grid>
  )
}
