import { Grid, Heading, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { client } from '../../utils/shopify';
import BlogItem from '../elements/BlogItem'

export default function BlogSection() {

  const [blogs, setBlogs] = useState(Array(4));

  useEffect(() => {
    const query = client.graphQLClient.query((root) => {
      root.addConnection("articles", { args: { first: 4, reverse: true} }, (node) => {
  
        node.add("id");
        node.add("title");
        node.add("contentHtml");
        node.add("excerpt");
        node.add("handle");

        node.add("image", (image) => {
          image.add("src");
        });

      });
    });

    // Call the send method with the custom products query
    client.graphQLClient.send(query).then(({ model, data }) => {
      // Do something with the products
      console.log(model);
      setBlogs(model.articles);
      console.log(data);
      //setProduct(model.productByHandle);
      //setVariant(model.productByHandle.variants[0]);
      //setLoading(false);
      //getStore(model.productByHandle);
    });
  }, []);


  return (
    <>
    {
    blogs.length > 0 && <Stack style={{paddingTop: "40px", paddingBottom: "40px"}} bg="rgba(47, 102, 53, 0.1)" spacing="30px" px="30px">
          <Stack align="center">
              <Heading color="#515151">Our Blog</Heading>
              <Text maxW="500px">Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</Text>
          </Stack>
          <Grid templateColumns={{base: "100%", md: "1fr 1fr 1fr 1fr"}} gap="20px" py="30px">
              {blogs.map((blog, index) => (
                <BlogItem blog={blog} key={index} />
              ))}
          </Grid>
      </Stack>
      }
    </>
  )
}
