import { Avatar, Box, Flex, keyframes, Link } from '@chakra-ui/react'
import React from 'react'

export default function Logo() {

    const size = '120px';
  
    const pulseRing = keyframes`
      0% {
      transform: scale(0.33);
    }
    40%,
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
      `;
  return (
    
    <Flex
        justifyContent="center"
        alignItems="center" position="relative"
        right={{base: 0, md: "unset"}}
        style={{position: "absolute",
            left: 0,
            marginLeft: "auto",
            marginRight: "auto",
            width: "100px" /* Need a specific value to work */}}>
        {/* Ideally, only the box should be used. The <Flex /> is used to style the preview. */}
        <Box
        as="div"
        position="absolute"
        left="0"
        right="0"
        zIndex="1"
        w={size}
        h={size}
       
        _before={{
            content: "''",
            position: 'relative',
            display: 'block',
            width: '300%',
            height: '300%',
            boxSizing: 'border-box',
            marginLeft: '-100%',
            marginTop: '-100%',
            borderRadius: '50%',
            bgColor: 'green.500',
            animation: `2.25s ${pulseRing} cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite`,
        }}>
        <Link href="/">
        <Avatar
            src="/Logo.svg"
            size="full"
            position="absolute"
            boxShadow="lg"
            bg={"#FFFCF6"}
            top={0}
            padding={"10px"}
        />
        </Link>
        </Box>
    </Flex>
  )
}
