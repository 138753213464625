import { Box, Stack } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/partials/Footer";
import Header from "./components/partials/header";
import BlogPage from "./pages/BlogPage";
import BlogsPage from "./pages/BlogsPage";
import Category from "./pages/Category";
import FaqPage from "./pages/FaqPage";
import Home from "./pages/Home";
import ProductPage from "./pages/Product";
import ScrollToTop from "./utils/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />
    <Stack>
       <Header />
       <Box>
            <Routes>
              <Route path="/" exact element={<Home />}>
                <Route index element={<Home />} />
                {/* <Route path="teams" element={<Teams />}>
                  <Route path=":teamId" element={<Team />} />
                  <Route path="new" element={<NewTeamForm />} />
                  <Route index element={<LeagueStandings />} />
                </Route> */}
              </Route>
              <Route path="product/:slug" element={<ProductPage />} />
              <Route path="blog/:slug" element={<BlogPage />} />
              <Route path="shop" element={<Category />} />
              <Route path="faqs" element={<FaqPage />} />
              <Route path="blogs" element={<BlogsPage />} />
            </Routes>
        </Box>
        <Footer />
    </Stack>
    </BrowserRouter>
  );
}

export default App;
