import { Grid, Heading, HStack, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { client } from '../../utils/shopify'
import SimpleProduct from '../elements/SimpleProduct'
import Birds from '../elements/utils/Birds'

export default function LatestProducts() {

  // create 4 empty products list
  const [products, setProducts] = useState(Array(4));
 
  useEffect(() => {

    client.product
      .fetchQuery({
        first: 4,
        sortKey: "CREATED_AT",
        reverse: true,
      })
      .then((results) => {
        // Do something with the products
        console.log(results);
        setProducts(results);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    

  }, []);
  return (
    <Stack spacing="30px">
        <Birds />
        <HStack align="end" justify="space-between">
             <Heading size="md">Latest Products</Heading>
             <Link to="/"><Text textDecoration="underline">View More</Text></Link>
        </HStack>
        <Grid templateColumns={{base: "100%", md: "1fr 1fr", lg: "1fr 1fr 1fr 1fr"}} gap="10px" zIndex="2">
            {products.map((product, index) => <SimpleProduct product={product} key={index} />)}
        </Grid>
    </Stack>
  )
}
